<template lang="pug">
v-row
  v-col.pb-5(cols="12" md="6")
    v-sheet.pa-5.pa-md-8.align-self-stretch.rounded-xl.re-cursor-pointer(@click="navigateToJournal(false)" style="height: 100%;")
      template(v-if="cashRegister.shiftOpen")
        .d-flex.justify-space-between.mb-2.mb-md-7
          p.text-body-1.text-md-h4 {{ `${$t("текущая_смена")} - №${shiftNumber}` }}
          p.text-body-1.text-md-h4.tertiary--text {{ shiftStartDateTime | moment("HH:mm - DD.MM.YYYY") }}
        .d-flex.justify-space-between.align-center
          p.text-h2.mb-0.text-md-h2.text-lg-h1.re-number-font {{ shiftRevenue | numeral('0,0.[00]') }} ₸
          v-chip.py-md-2.px-md-4.d-table.py-1(x-small color="reBackground lighten-2")
            p.text-subtitle-2.mb-0.text-md-h4 {{ `${shiftTicketNumber} ${numWord(shiftTicketNumber)}` }}
        template(v-if="cashRegister.shiftExpired")
          v-btn.mt-4.mb-2.mt-md-8.d-flex.mx-auto.text-subtitle-2.text-md-subtitle-1.rounded-lg(color="reBackground lighten-2" width="100%" @click.stop="navigateToJournal(true)")
            v-icon.mr-3(left size="20" color="red") mdi-alert-circle
            | {{ $t("необходимо_закрыть_смену") }}
      template(v-else)
        p.text-subtitle-2.text-center.text-md-h3 {{ $t("нет_открытой_смены") }}
        v-btn.mt-4.mb-2.mt-md-8.d-flex.mx-auto.text-subtitle-2.text-md-subtitle-1.rounded-lg(width="100%" color="orange" @click.stop="$router.push('/kkm')") {{ $t("открыть_новую_смену") }}

  v-col.d-flex.py-0.py-md-3.px-4(cols="12" md="6")
    v-row

      //- First Button ----------------------------------------------------------------------------
      v-col.d-flex.pt-md-0.pa-2(cols="4" @click="showMiniAppUPlus(); analyticsLogEvent({ eventName: 're_inventory_clicked_menu', eventProperties: { section_source: 'from_re_main_page' } })")
        v-sheet.pa-4.flex-grow-1.align-self-stretch.rounded-xl.re-cursor-pointer.justify-center.align-center
          .d-flex.flex-column.align-center
            v-avatar.mt-md-3(color="reBackground" :size="avatarSize")
              v-icon(:size="avatarIconSize") mdi-package-variant
            p.text-center.text-subtitle-2.text-md-h4.mt-2.mt-md-3 {{ $t("товары_и_услуги") }}
        div(style="position: relative;")
          .text-subtitle-2.new-badge new

      //- Second Button ----------------------------------------------------------------------------
      template(v-if="nAMiniAppEnabled")
        v-col.d-flex.pt-md-0.pa-2(cols="4" @click="showMiniAppNA(); analyticsLogEvent({ eventName: 're_salyq_clicked_menu', eventProperties: { section_source: 'from_re_main_page' } })")
          v-sheet.pa-4.flex-grow-1.align-self-stretch.rounded-xl.re-cursor-pointer.justify-center.align-center
            .d-flex.flex-column.align-center
              v-avatar.mt-md-3(color="reBackground" :size="avatarSize")
                v-icon(:size="avatarIconSize") mdi-file-percent-outline
              p.text-center.text-subtitle-2.text-md-h4.mt-2.mt-md-3 {{ $t("налоги") }}
          div(style="position: relative;")
            .text-subtitle-2.new-badge new

      template(v-else)
        v-col.d-flex.pt-md-0.pa-2(cols="4" @click="$router.push('/kkm')")
          v-sheet.pa-4.flex-grow-1.align-self-stretch.rounded-xl.re-cursor-pointer.justify-center.align-center
            .d-flex.flex-column.align-center
              v-avatar.mt-md-3(color="reBackground" :size="avatarSize")
                v-icon(:size="avatarIconSize") mdi-calculator-variant
              p.text-center.text-subtitle-2.text-md-h4.mt-2.mt-md-3 {{ $t("касса") }}

      //- Third Button ----------------------------------------------------------------------------
      v-col.d-flex.pt-md-0.pa-2(cols="4" @click="$openLink(`https://link.rekassa.kz/help-rekassa-${locale}`, '_system'); analyticsLogEvent({ eventName: 're_dash_tile_clicked', eventProperties: { tile_name: 'manuals' } })")
        v-sheet.py-4.pa-md-4.flex-grow-1.align-self-stretch.rounded-xl.re-cursor-pointer.justify-center.align-center
          .d-flex.flex-column.align-center
            v-avatar.mt-md-3(color="reBackground" :size="avatarSize")
              v-icon(:size="avatarIconSize") mdi-microsoft-onenote
            p.text-center.text-subtitle-2.text-md-h4.mt-2.mt-md-3 {{ $t("справочник") }}

</template>
<script>
import { mapState, mapActions } from 'vuex'
import { getLocale } from '../../../i18n/index'
import billsAndCoinsMixin from '../../../mixins/billsAndCoinsMixin'

export default {
  mixins: [billsAndCoinsMixin],

  data: () => ({
    nAMiniAppEnabled: false,
    nAMiniAppUrl: null,
    nAMiniAppName: null,
  }),

  computed: {
    ...mapState({
      user: state => state.auth.user,
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
    }),

    locale() {
      return getLocale()
    },

    shift() {
      return this.cashRegister ? this.cashRegister.shift : null
    },

    shiftNumber() {
      if (this.shift && this.shift.open && this.shift.shiftNumber) {
        return this.shift.shiftNumber
      }
      return null
    },

    shiftRevenue() {
      if (this.shift && this.shift.open && this.shift.data.revenue) {
        return this.getNumberFromBillsAndCoins(this.shift.data.revenue.sum) * (this.shift.data.revenue.isNegative ? -1 : 1)
      }
      return null
    },

    shiftTicketNumber() {
      if (this.cashRegister && this.shift && this.shift.open && this.shift.shiftTicketNumber > 0) {
        return this.shift.shiftTicketNumber
      }
      return 0
    },

    shiftStartDateTime() {
      if (this.shift && this.shift.openTime) {
        return this.shift.openTime
      }
      return null
    },

    isLarge() {
      return this.$vuetify.breakpoint.mdAndUp
    },

    avatarSize() {
      return this.isLarge ? '80px' : '65px'
    },

    avatarIconSize() {
      return this.isLarge ? '44px' : '36px'
    },
  },

  watch: {
    $route() {
      this.handleRouteChange()
    },
  },

  mounted() {
    Promise.all([
      this.checkNAAccess(),
    ]).then(() => {
      this.handleRouteChange()
    }).catch(error => {
      console.error('Error during mounted lifecycle:', error)
    })
  },

  methods: {
    ...mapActions({
      firebaseRemoteConfigGetString: 'tools/firebaseRemoteConfigGetString',
      showCashRegisterSwitch: 'tools/showCashRegisterSwitch',
      showConfirm: 'tools/showConfirm',
      analyticsLogEvent: 'analytics/logEvent',
      showSnackbar: 'tools/showSnackbar',
      showMiniApp: 'miniapp/show',
    }),

    async checkNAAccess() {
      try {
        const result = await this.firebaseRemoteConfigGetString('NA_CONFIG')
        const config = JSON.parse(result)
        this.nAMiniAppEnabled = config.enabled
        this.nAMiniAppUrl = config.url
        this.nAMiniAppName = config.name

        if (!this.nAMiniAppEnabled && config.allowedUIDs && config.allowedUIDs.includes(this.user.uid)) {
          this.nAMiniAppEnabled = true
        }
      } catch (error) {
        console.error('Error parsing NA_CONFIG', error)
      }
    },

    showMiniAppUPlus() {
      this.showMiniApp({
        url: process.env.VUE_APP_MINIAPP_UPLUS_URL,
        name: process.env.VUE_APP_MINIAPP_UPLUS_NAME,
        params: [
          { viewType: 'home' },
        ],
      })
    },

    showMiniAppNA() {
      this.showMiniApp({
        url: this.nAMiniAppUrl,
        name: this.nAMiniAppName,
      })
    },

    handleRouteChange() {
      const { path, fullPath, query } = this.$route

      // Handle '/list' path
      if (path === '/list') {
        this.showCashRegisterSwitch(true)
        this.$router.push('/')
        return
      }

      // Handle '/miniapp' path with query name
      if (fullPath.startsWith('/miniapp') && query.name) {
        const { name } = query

        if (name === 'uplus' && this.uPlusMiniAppEnabled) {
          this.showMiniAppUPlus()
        } else if (name === 'na' && this.nAMiniAppEnabled) {
          this.showMiniAppNA()
        }

        // Clear path
        this.$router.push('/')
      }
    },

    navigateToJournal(zxreport) {
      if (this.shiftNumber) {
        this.$router.push(`/shifts/${this.cashRegister.id}/${this.shiftNumber}${zxreport ? '/zxreport' : ''}`)
      }
    },

    numWord(value) {
      const words = this.locale === 'ru' ? ['чек', 'чека', 'чеков'] : ['чек', 'чек', 'чек']
      value = Math.abs(value) % 100
      const num = value % 10
      if (value > 10 && value < 20) return words[2]
      if (num > 1 && num < 5) return words[1]
      if (num === 1) return words[0]
      return words[2]
    },
  },
}
</script>
<style scoped lang="stylus">
.mdi-alert-circle::before
  background-color white
  border-radius 15px

.new-badge
  position absolute
  top 16px
  right -3px
  background-color rgb(244, 67, 54)
  padding 1px 7px
  border-top-left-radius 7px
  border-bottom-left-radius 7px
  border-top-right-radius 3px
  border-bottom-right-radius 3px
  color white
</style>
