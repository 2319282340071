<template lang="pug">
v-bottom-sheet(v-model="ofdRegBottomSheet" fullscreen)
  v-sheet
    iframe.re-fullscreen-iframe(ref="ofdRegIframe" :src="ofdRegSrc")
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { getLocale } from '../../i18n'

export default {
  name: 'OFDRegistration',

  data() {
    return {
      ofdRegBottomSheet: false,
      ofdRegSrc: null,
      ofdRegIAB: null,
      ofdRegTimerId: null,
      appOfdVersion: null,
    }
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
      ofdRegIframe: state => state.tools.ofdRegIframe,
      auth: state => state.auth,
    }),
  },

  watch: {
    ofdRegIframe(val) {
      if (val) {
        this.open()
      }
    },

    $route(to) {
      if (to.fullPath.startsWith('/ofdreg')) {
        this.open(this.$route.query.id, this.$route.query.cashRegisterId)
        this.$router.push('/')
      }
    },
  },

  mounted() {
    if (this.$route.path.startsWith('/ofdreg')) {
      this.open(this.$route.query.id, this.$route.query.cashRegisterId)
      this.$router.push('/')
    }

    if (this.$route.query['appofd-version']) {
      this.appOfdVersion = this.$route.query['appofd-version'].replace(/[{}]/g, '')
    }
  },

  methods: {
    ...mapActions({
      showOfdRegIframe: 'tools/showOfdRegIframe',
      init: 'cashRegisters/init',
      analyticsLogEvent: 'analytics/logEvent',
      copyToClipboard: 'tools/copyToClipboard',
      setGlobalLoading: 'tools/setGlobalLoading',
      showSnackbar: 'tools/showSnackbar',
    }),

    open(id, cashRegisterId) {
      if (this.$isCordova()) {
        this.ofdRegIAB = undefined
        this.ofdRegIAB = window.cordova.InAppBrowser.open(`${process.env.VUE_APP_OFD_REGISTRATION_URL}?phone=${this.user.phone}&lang=${getLocale()}&isApp=true${id ? `&id=${id}` : ''}${cashRegisterId ? `&cashRegisterId=${cashRegisterId}` : ''}${this.cashRegister.status === 'REGISTERED' ? `&iinBin=${this.organization.businessId}` : ''}`, '_blank', 'location=no,toolbar=no,hidenavigationbuttons=yes,hideurlbar=yes,zoom=no')
        this.ofdRegIAB.addEventListener('message', this.onMessage)
        this.ofdRegIAB.addEventListener('exit', this.onExit)
      } else {
        window.addEventListener('message', this.onMessage, false)
        this.ofdRegSrc = `${process.env.VUE_APP_OFD_REGISTRATION_URL}${this.appOfdVersion ? `/${this.appOfdVersion}/` : ''}?phone=${this.user.phone}&lang=${getLocale()}&isApp=false${id ? `&id=${id}` : ''}${cashRegisterId ? `&cashRegisterId=${cashRegisterId}` : ''}${this.cashRegister.status === 'REGISTERED' ? `&iinBin=${this.organization.businessId}` : ''}`
        this.ofdRegBottomSheet = true
      }

      this.ofdRegTimerId = setTimeout(() => {
        this.close()
        this.showSnackbar({ message: this.$t('произошла_ошибка') })
      }, 20000)
    },

    onMessage(event) {
      if (event.data && (this.$isCordova() || event.origin === process.env.VUE_APP_OFD_REGISTRATION_URL)) {
        const data = this.$isCordova() ? event.data : JSON.parse(event.data)

        // loaded
        if (data.action === 'loaded') {
          if (this.ofdRegTimerId) clearTimeout(this.ofdRegTimerId)
        }

        // close
        if (data.action === 'close') {
          if (this.ofdRegTimerId) clearTimeout(this.ofdRegTimerId)
          this.close()
        }

        // token
        if (data.action === 'token') {
          (async () => {
            if (this.$isCordova()) {
              // Cordova
              console.debug('Command "egovInstalled" cordova start')
              const currentUser = await new Promise(resolve => { window.FirebasePlugin.getCurrentUser((user) => resolve(user)) })
              if (currentUser) {
                const token = await currentUser.idToken
                const postData = {
                  name: 'token',
                  value: token,
                }
                this.ofdRegIAB.executeScript({ code: `window.onPostMessage('${postData.name}', '${postData.value}');` })
                console.debug('Command "egovInstalled" cordova end')
              }
              // Web
            } else {
              console.debug('Command "egovInstalled" web start')
              const { currentUser } = this.auth.firebaseAuth
              if (currentUser) {
                const token = await currentUser.getIdToken()
                const postData = {
                  name: 'token',
                  value: token,
                }
                this.$refs.ofdRegIframe.contentWindow.postMessage(postData, process.env.VUE_APP_OFD_REGISTRATION_URL)
                console.debug('Command "egovInstalled" web end')
              }
            }
          })()
        }

        // egovInstalled
        if (data.action === 'egovInstalled') {
          window.cordova.plugins.rekassaAppavailability.hasEgovMobile((result) => {
            const postData = {
              name: 'egovInstalled',
              value: result,
            }
            if (this.$isCordova()) {
              console.debug('Command "egovInstalled" cordova start')
              this.ofdRegIAB.executeScript({ code: `window.onPostMessage('${postData.name}', '${postData.value}');` })
              console.debug('Command "egovInstalled" cordova end')
            } else {
              console.debug('Command "egovInstalled" web start')
              this.$refs.ofdRegIframe.contentWindow.postMessage(postData, process.env.VUE_APP_OFD_REGISTRATION_URL)
              console.debug('Command "egovInstalled" web end')
            }
          }, () => {})
        }

        // egovBusinessInstalled
        if (data.action === 'egovBusinessInstalled') {
          window.cordova.plugins.rekassaAppavailability.hasEgovBusiness((result) => {
            const postData = {
              name: 'egovBusinessInstalled',
              value: result,
            }
            if (this.$isCordova()) {
              console.debug('Command "egovBusinessInstalled" cordova start')
              this.ofdRegIAB.executeScript({ code: `window.onPostMessage('${postData.name}', '${postData.value}');` })
              console.debug('Command "egovBusinessInstalled" cordova end')
            } else {
              console.debug('Command "egovBusinessInstalled" web start')
              this.$refs.ofdRegIframe.contentWindow.postMessage(postData, process.env.VUE_APP_OFD_REGISTRATION_URL)
              console.debug('Command "egovBusinessInstalled" web end')
            }
          }, () => {})
        }

        // openUrl
        if (data.action === 'openUrl' && data.url) {
          console.debug('Command "openUrl" start')
          this.ofdRegIAB = this.$openLink(data.url, '_system')
          if (this.$isCordova()) {
            this.ofdRegIAB.addEventListener('message', this.onMessage)
            this.ofdRegIAB.addEventListener('exit', this.onExit)
          }
          console.debug('Command "openUrl" end')
        }

        // share
        if (data.action === 'share' && data.url) {
          console.debug('Command "share" start')
          if (navigator.share) {
            navigator.share({
              title: this.$t('отправить_ссылку'),
              text: data.url,
            })
          } else {
            this.copyToClipboard(data.url)
          }
          console.debug('Command "share" end')
        }

        // logEvent
        if (data.action === 'logEvent' && data.url) {
          console.debug('Command "logEvent" start')
          this.analyticsLogEvent({ eventName: data.url })
          console.debug('Command "logEvent" end')
        }

        // finish
        if (data.action === 'finish' && data.url) {
          console.debug(`Command "finish" start with data: ${data.url}`)
          this.close()
          this.setGlobalLoading(true)
          this.init({ params: { cashRegisterId: data.url } }).then(() => {
            this.setGlobalLoading(false)
            this.analyticsLogEvent({ eventName: 're_cash_register_activated_landing', eventProperties: { phone: this.user.phone } })
          })
          console.debug('Command "finish" end')
        }
      }
    },

    onExit() {
      console.debug('Command "onExit" cordova start')
      this.ofdRegIAB.removeEventListener('message', this.onMessage)
      this.ofdRegIAB = undefined
      this.showOfdRegIframe(false)
      this.init().then(() => {})
      console.debug('Command "onExit" cordova start')
    },

    close() {
      if (this.$isCordova()) {
        console.debug('Command "close" cordova start')
        this.ofdRegIAB.close()
        console.debug('Command "close" cordova end')
      } else {
        console.debug('Command "close" web start')
        this.ofdRegBottomSheet = false
        this.ofdRegSrc = null
        window.removeEventListener('message', this.onMessage, false)
        this.showOfdRegIframe(false)
        this.init().then(() => {})
        console.debug('Command "close" web end')
      }
    },

  },

}
</script>

<style lang="stylus">
</style>
